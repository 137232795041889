import axios from "axios";

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:8000";

const axiosInstance = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  timeout: 55000, // 55 sec (less than 60 sec default nginx)
  headers: {Accept: "application/json", "Content-Type": "application/json"},
});

axiosInstance.interceptors.request.use(async (config) => {
  if (config.params.locale) {
    config.headers["Accept-Language"] = config.params.locale;
    delete config.params.locale;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error.response && error.response.data) {
      console.log(
        "axiosInstance.error.response.data",
        JSON.stringify(error.response.data),
      );
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
