import {NextSeo} from "next-seo";
import {useRouter} from "next/router";
import {Seo} from "../campaigns.types";

const APP_BASE_URL =
  process.env.NEXT_PUBLIC_APP_BASE_URL || "https://app.rescuenow.com.ua";

interface PageSeoProps {
  seo: Seo;
}

export function PageSeo(props: PageSeoProps) {
  const {seo} = props;

  const router = useRouter();

  const siteName = "RescueNOW";
  const pageUrl = `${APP_BASE_URL}${router.asPath}`;

  const title = `${seo.title} | ${siteName}`;
  return (
    <NextSeo
      title={title}
      description={seo.description}
      noindex={seo.noindex}
      nofollow={seo.nofollow}
      openGraph={{
        url: pageUrl,
        title: title,
        description: seo.description,
        images: [{url: seo.image}],
        locale: router.locale,
        site_name: siteName,
      }}
      twitter={{
        handle: "@handle",
        site: "@site",
        cardType: "summary_large_image",
      }}
    />
  );
}
