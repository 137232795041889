import axios from "./axios";

export function campaignsList(params = {}) {
  return axios.get("/campaigns/", {params: {limit: 12, ...params}});
}

export function campaignsRetrieve(id, params = {}) {
  return axios.get(`/campaigns/${id}/`, {params});
}

export function campaignsTransactionsCreate(id, data, params = {}) {
  return axios.post(`/campaigns/${id}/transactions/`, data, {params});
}

export function campaignsTransactionsList(id, params = {}) {
  return axios.get(`/campaigns/${id}/transactions/`, {
    params: {limit: 2, ...params},
  });
}

export function campaignsReportsList(id, params = {}) {
  return axios.get(`/campaigns/${id}/reports/`, {
    params: {limit: 30, ...params},
  });
}

export function campaignsSeoRetrieve(id, params = {}) {
  return axios.get(`/campaigns/${id}/seo/`, {
    params,
  });
}

export function campaignCategoriesList(params = {}) {
  return axios.get(`/campaign-categories/`, {
    params: {limit: 100, ...params},
  });
}
