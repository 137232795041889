
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {Waypoint} from "react-waypoint";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "components/link";
import {
  Campaign,
  CampaignsResponse,
  CategoriesResponse,
  Category,
  Seo,
} from "feat/campaigns/campaigns.types";
import {CampaignPreviewCard} from "feat/campaigns/components/campaign-preview-card";
import {PageSeo} from "feat/campaigns/components/page-seo";
import {not} from "feat/utils/boolean";
import isEmpty from "lodash/isEmpty";
import {GetServerSideProps} from "next";
import useTranslation from "next-translate/useTranslation";
import {useRouter} from "next/router";
import {Fragment, useEffect, useState} from "react";
import * as campaignsService from "../../services/campaigns";
import * as seoService from "../../services/seo";
import {CircularProgress, getInputAdornmentUtilityClass} from "@mui/material";

interface CampaignsProps {
  campaigns: CampaignsResponse;
  categories: CategoriesResponse;
  seo?: Seo;
}

export default function CampaignsPage(props: CampaignsProps) {
  const router = useRouter();
  const {t} = useTranslation();

  const [campaigns, setCampaigns] = useState(props.campaigns.results);
  useEffect(
    () => setCampaigns(props.campaigns.results),
    [props.campaigns.results],
  );

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  async function loadMore() {
    if (isLoadingMore || campaigns.length >= props.campaigns.count) {
      return;
    }

    setIsLoadingMore(true);
    const params: Record<string, any> = getParams(router);
    const moreCampaigns = await campaignsService.campaignsList({
      ...params,
      offset: campaigns.length,
    });

    setCampaigns(campaigns.concat(moreCampaigns.data.results));
    setIsLoadingMore(false);
  }

  return (
    <Fragment>
      <PageSeo seo={props.seo} />
      <Box my={3}>
        <Stack spacing={2} display="flex">
          <Typography variant="h3" component="h3" textAlign="center">
            {t("campaigns:bannerHelpText")}
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            {t("campaigns:bannerHelpSubText")}
          </Typography>

          <CampaignsForm>
            <CategorySelect categories={props.categories.results} />
            <FilterRadioGroup />
          </CampaignsForm>

          <Campaigns campaigns={campaigns} />

          <Box
            alignItems="center"
            justifyContent="center"
            sx={{display: "flex"}}
          >
            {isLoadingMore && <CircularProgress />}
          </Box>
        </Stack>
      </Box>
      <Waypoint bottomOffset="-300px" onEnter={loadMore} />
    </Fragment>
  );
}

function Campaigns(props: {campaigns: Campaign[]}) {
  if (props.campaigns.length === 0) {
    return <NoResults />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gap: 3,
        gridAutoRows: "1fr",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
      }}
    >
      {props.campaigns.map((campaign) => {
        return (
          <Link
            href={`/campaigns/${campaign.slug}`}
            key={campaign.slug}
            underline="none"
          >
            <CampaignPreviewCard campaign={campaign} />
          </Link>
        );
      })}
    </Box>
  );
}

function CampaignsForm(props: {children: React.ReactNode}) {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Stack
        direction={{xs: "column", md: "row"}}
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        {props.children}
      </Stack>
    </form>
  );
}

function CategorySelect({categories}: {categories: Category[]}) {
  const name = "category";

  const router = useRouter();

  const {t} = useTranslation();
  const label = t("campaigns:selectCampaignCategory");

  const defaultValue = "all";
  const fromQuery = Array.isArray(router.query[name])
    ? null
    : router.query[name];
  const value = fromQuery || defaultValue;

  function handleChange(value: string | null) {
    const query = {...router.query, [name]: value};
    if (not(query[name])) {
      delete query[name];
    }

    if (isEmpty(query)) {
      router.push(router.pathname);
    } else {
      router.push({query});
    }
  }

  return (
    <FormControl sx={{width: {xs: "100%", md: "240px"}}}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        onChange={(e) => {
          const value = e.target.value;
          handleChange(value === defaultValue ? null : value);
        }}
        id={name}
        label={label}
        name={name}
        native
        sx={{backgroundColor: "white"}}
        value={value}
      >
        <option value={defaultValue}>
          {t("campaigns:selectCampaignCategoryAll")}
        </option>
        {categories.map((category) => {
          return (
            <option key={category.pk} value={category.slug}>
              {category.title}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
}

function FilterRadioGroup() {
  const name = "sort";

  const router = useRouter();
  const {t} = useTranslation();

  const defaultValue = "all";
  const fromQuery = Array.isArray(router.query[name])
    ? null
    : router.query[name];
  const value = fromQuery || defaultValue;

  function handleChange(value: string | null) {
    const query = {...router.query, [name]: value};
    if (not(query[name])) {
      delete query[name];
    }

    if (isEmpty(query)) {
      router.push(router.pathname);
    } else {
      router.push({query});
    }
  }

  return (
    <FormControl>
      <RadioGroup
        id={name}
        name={name}
        onChange={(_e, value) => {
          handleChange(value === defaultValue ? null : value);
        }}
        row
        value={value}
      >
        <FormControlLabel
          control={<Radio />}
          label={t("campaigns:sortCampaignsHot")}
          value="hot"
        />
        <FormControlLabel
          control={<Radio />}
          label={t("campaigns:sortCampaignsNew")}
          value="new"
        />
        <FormControlLabel
          control={<Radio />}
          label={t("campaigns:sortCampaignsFinished")}
          value="finished"
        />
        <FormControlLabel
          control={<Radio />}
          label={t("campaigns:sortCampaignsAll")}
          value="all"
        />
      </RadioGroup>
    </FormControl>
  );
}

function NoResults() {
  const router = useRouter();
  const {t} = useTranslation("campaigns");

  return (
    <Stack spacing={2} sx={{paddingTop: {xs: 2, md: 10}}} alignItems="center">
      <Typography component="h2" variant="h2" align="center">
        {t("noResults-heading")}
      </Typography>
      <Typography align="center" variant="body2">
        {t("noResults-content")}
      </Typography>
      <Link href={{pathname: router.pathname}}>{t("noResults-resetLink")}</Link>
    </Stack>
  );
}

function getParams(router) {
  const {sort, ...query} = router.query;

  const params: Record<string, any> = {...query, locale: router.locale};

  if (typeof sort === "string") {
    const sortParamsMap = {
      finished: {key: "status", value: "finished"},
      hot: {key: "is_hot", value: true},
      new: {key: "order_by", value: "-created_at"},
    };
    const sortParam = sortParamsMap[sort];
    if (sortParam) {
      params[sortParam.key] = sortParam.value;
    }
  }

  return params;
}

 const _getServerSideProps: GetServerSideProps = async (ctx) => {
  const params: Record<string, any> = getParams(ctx);
  const campaignsPromise = campaignsService.campaignsList(params);

  function getFullPath(ctx) {
    return (
      (ctx.locale === ctx.defaultLocale ? "" : `/${ctx.locale}`) +
      ctx.resolvedUrl.split("?")[0]
    );
  }
  const campaignsPageSeoPromise = seoService.pageSeoRetrieve({
    ...params,
    path: getFullPath(ctx),
  });
  const categoriesPromise = campaignsService.campaignCategoriesList(params);

  const results = await Promise.all([
    campaignsPromise,
    campaignsPageSeoPromise,
    categoriesPromise,
  ]);
  const [campaignsResponse, campaignsPageSeoResponse, categoriesResponse] =
    results;

  const campaignsData = campaignsResponse.data as CampaignsResponse;

  const campaignsPageSeoData = campaignsPageSeoResponse.data;
  const categoriesData = categoriesResponse.data as CategoriesResponse;

  const props: CampaignsProps = {
    campaigns: campaignsData,
    categories: categoriesData,
    seo: campaignsPageSeoData,
  };

  return {props};
};


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/campaigns/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  