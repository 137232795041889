import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Campaign} from "feat/campaigns/campaigns.types";
import {not} from "feat/utils/boolean";
import useTranslation from "next-translate/useTranslation";
import {useState} from "react";

interface CampaignPreviewCardProps {
  campaign: Campaign;
  minWidth?: string | number;
}

export function CampaignPreviewCard(props: CampaignPreviewCardProps) {
  const {campaign, minWidth = "300px"} = props;

  const [hovered, setHovered] = useState(false);

  const {t} = useTranslation("campaigns");

  const progress = campaign.receivedAmount
    ? (campaign.receivedAmount / Number(campaign.requiredAmount)) * 100
    : 0;

  const isFinished = campaign.status === "finished";
  const hasNoRequiredAmount = Number(campaign.requiredAmount) == 0;

  return (
    <Card
      sx={{
        minWidth,
        flex: 1,
        borderRadius: 0,
        border: "none",
        height: "100%",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.04)",
      }}
      variant="outlined"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Box sx={{height: "200px", width: "100%"}}>
          <CardMedia
            alt={campaign.title}
            component="img"
            height="200"
            image={campaign.coverThumbnail || ""}
          />
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(24, 144, 255, 0.8)",
            position: "absolute",
            top: 0,
            left: 0,
            height: "200px",
            width: "100%",
            display: hovered ? "flex" : "none",
          }}
        >
          <Typography color="white" variant="h4">
            {t("learnMore")}
          </Typography>
        </Stack>
        <CardContent
          sx={{
            minHeight: "120px",
            height: "100%",
            maxHeight: "200px",
            width: "100%",
          }}
        >
          <Stack spacing={2} sx={{height: "100%"}}>
            <Stack sx={{flexGrow: 1}} spacing={1}>
              <Typography variant="subtitle2" component="h3">
                {campaign.category.title}
              </Typography>
              {/* TODO: add trim */}
              <Typography variant="h4" component="h2">
                {campaign.title}
              </Typography>
            </Stack>
            {not(isFinished) && not(hasNoRequiredAmount) && (
              <Progress value={progress} />
            )}
            {isFinished && <FinishedTag />}
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}

function FinishedTag() {
  const {t} = useTranslation("campaigns");

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{color: "primary.main"}}
    >
      <CheckCircleIcon />{" "}
      <Typography variant="body1">{t("finishedStatus")}</Typography>
    </Stack>
  );
}

function Progress(props: {value: number}) {
  const value = Math.round(props.value);

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box sx={{width: "100%"}}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{minWidth: "35px"}}>
        <Typography variant="body2">{`${value}%`}</Typography>
      </Box>
    </Stack>
  );
}
